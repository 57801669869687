div.article {
  position: relative;
  background-color: rgba(27, 31, 34, 0.85);
  width: 40%;
  border-radius: 5px;
}

div.article > div.article-content {
  padding: 40px;
}

div.a-content {
  width: 100%;
  color: white;
}

div.a-content > img {
  width: 100%;
  border-radius: 5px;
  margin: 0 0 2em;
}

div.a-content > p {
  line-height: 1.7rem;
  font-size: 0.9vw;
  /* margin: 25px; */
}

div.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 10px;
  border-radius: 50%;
  margin: 5px;
}

h2.major {
  color: white;
  text-transform: uppercase;
  border-bottom: 2px solid white;
  line-height: 1.4;
  letter-spacing: 0.5rem;
  width: max-content;
  margin: 0 0 2em;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
}

div.close-icon:hover {
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.109);
  border-radius: 50%;
  cursor: pointer;
}
