div#bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: -1;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out;
  background-repeat: no-repeat;
  box-sizing: border-box;
  transform: scale(1.082);
  overflow: hidden;
}

div#bg-img-open {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: -1;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out;
  transform: scale(1);
  box-sizing: border-box;
}

footer {
  color: white;
  letter-spacing: 0.2rem;
}

div.footer-section {
  width: 100vw;
  display: flex;
  justify-content: center;
}

a.para-link {
  color: white;
}

div.header-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
}

div#articles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

div.name-email {
  display: flex;
}

.input {
  background: transparent;
  padding: 10px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  outline: none;
  color: white;
  /* margin: 20px; */
  border: 1px solid white;
  border-radius: 5px;
}

/* div.name-input {
  margin: 10px 0px;
} */

.input-sec {
  margin: 20px 15px;
}

textarea#message {
  width: 100%;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
}

label {
  text-transform: uppercase;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  letter-spacing: 5px;
}

button.input-button {
  padding: 15px;
  border-radius: 5px;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  background: transparent;
  border: 1px solid white;
  cursor: pointer;
  color: white;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0px 10px;
  font-weight: 900px;
}

button.focus {
  background-color: white;
  /* font-family: source sans pro, Helvetica, Arial, sans-serif; */
  color: black;
}

.input:focus {
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  border-color: 2px solid white;
  background-color: rgba(255, 255, 255, 0.089);
}
