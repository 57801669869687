@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap);
body {
  margin: 0;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
}

span.nav-title > a {
  font-size: x-large;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

span.nav-title > a:hover {
  color: #2f5ffd;
}

span.nav-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.nav-heading > a {
  margin: 0px 6px;
}

span.nav-heading > a > img:hover {
  opacity: 0.5;
}

span.buttons {
  display: flex;
}

span.buttons > a {
  text-decoration: none;
  border-radius: 3px;
  margin: 0px 10px;
  color: #2f5ffd;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #2f5ffd;
  font-weight: bold;
}

span.buttons > a.focused {
  background-color: #2f5ffd;
  color: white;
}

span.buttons > a:hover {
  opacity: 0.8;
}

div.article {
  position: relative;
  background-color: rgba(27, 31, 34, 0.85);
  width: 40%;
  border-radius: 5px;
}

div.article > div.article-content {
  padding: 40px;
}

div.a-content {
  width: 100%;
  color: white;
}

div.a-content > img {
  width: 100%;
  border-radius: 5px;
  margin: 0 0 2em;
}

div.a-content > p {
  line-height: 1.7rem;
  font-size: 0.9vw;
  /* margin: 25px; */
}

div.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 10px;
  border-radius: 50%;
  margin: 5px;
}

h2.major {
  color: white;
  text-transform: uppercase;
  border-bottom: 2px solid white;
  line-height: 1.4;
  letter-spacing: 0.5rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 0 2em;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
}

div.close-icon:hover {
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.109);
  border-radius: 50%;
  cursor: pointer;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

span.diamond {
  border: 1px solid white;
  position: relative;
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

span.diamond > img {
  width: 70%;
  height: 70%;
}

span#varticle-line {
  background-color: white;
  height: calc(3.5rem + 1px);
  width: 1px;
  position: absolute;
  left: 50%;
  top: 100%;
}

div.content {
  width: 50%;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  margin: calc(3.5rem + 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  align-items: center;
}

div.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 3rem 2rem;
}

div.inner > p {
  color: white;
  font-size: 0.89vw;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  line-height: 2.5;
}

div.inner > h2 {
  color: white;
  font-size: 2.25rem;
  font-weight: 600px;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

div.nav-buttons {
  display: flex;
  border: 0.5px solid white;
  border-radius: 5px;
}

div.nav-buttons > button {
  background-color: transparent;
  transition: 0.25s ease-in-out;
  display: block;
  min-width: 8rem;
  height: 3rem;
  line-height: 2.75rem;
  padding: 0 1.25rem 0 1.45rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  border: none;
  font-family: Source Sans Pro, sans-serif;
  color: white;
  border-right: 1px solid white;
  cursor: pointer;
}

div.nav-buttons > button:hover {
  transition: 0.25s ease-in-out;
  background-color: rgba(255, 255, 255, 0.096);
}

a.hover-link {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  padding: 0px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: white;
  text-decoration: none;
  border-bottom: 1px dotted hsla(0, 0%, 100%, 0.5);
}

a.hover-link:hover {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  border-bottom: 1px dotted hsla(0, 0%, 100%, 0);
}

div#bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: -1;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    -webkit-filter 0.325s ease-in-out;
  background-repeat: no-repeat;
  box-sizing: border-box;
  transform: scale(1.082);
  overflow: hidden;
}

div#bg-img-open {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  z-index: -1;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    -webkit-filter 0.325s ease-in-out;
  transform: scale(1);
  box-sizing: border-box;
}

footer {
  color: white;
  letter-spacing: 0.2rem;
}

div.footer-section {
  width: 100vw;
  display: flex;
  justify-content: center;
}

a.para-link {
  color: white;
}

div.header-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
}

div#articles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

div.name-email {
  display: flex;
}

.input {
  background: transparent;
  padding: 10px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  outline: none;
  color: white;
  /* margin: 20px; */
  border: 1px solid white;
  border-radius: 5px;
}

/* div.name-input {
  margin: 10px 0px;
} */

.input-sec {
  margin: 20px 15px;
}

textarea#message {
  width: 100%;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
}

label {
  text-transform: uppercase;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  letter-spacing: 5px;
}

button.input-button {
  padding: 15px;
  border-radius: 5px;
  font-family: source sans pro, Helvetica, Arial, sans-serif;
  background: transparent;
  border: 1px solid white;
  cursor: pointer;
  color: white;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0px 10px;
  font-weight: 900px;
}

button.focus {
  background-color: white;
  /* font-family: source sans pro, Helvetica, Arial, sans-serif; */
  color: black;
}

.input:focus {
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  border-color: 2px solid white;
  background-color: rgba(255, 255, 255, 0.089);
}

